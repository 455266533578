import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import FloatingBuyButton from './components/FloatingBuyButton';
import DexList from './components/DexList';
// import VantaBackground from './components/VantaBackground';
import HowToBuySoL from './components/HowToBuySol';


function App() {
  return (
    <div className="App relative min-h-screen">
    <Header />
    <Hero />
    <HowToBuySoL />
    <DexList />
    <Tokenomics />
   
    <Roadmap />
    <About />
    <Footer />
    <FloatingBuyButton />
  </div>
  );
}

export default App;
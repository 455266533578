import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-labubu-pink-100 text-labubu-pink-800 py-10">
      <div className="container mx-auto text-center">
        <p>© 2024 Labubusol. All Rights Reserved.</p>
        <div className="flex justify-center space-x-6 mt-4">
          {/* Add social media icons */}
          <a href="https://x.com/labubu_CTO" className="hover:text-labubu-pink-600">
            Twitter
          </a>
          <a href="https://t.co/UoNEHrcSN3" className="hover:text-labubu-pink-600">
            Telegram
          </a>
          <a href="https://dexscreener.com/solana/fyanfcdjkcfakbtzqixntqnvlodjjft82l5ffgpaswe3" className="hover:text-labubu-pink-600">
            DexScreener
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React from 'react';

const DexList = () => {
  const exchanges = [
    { name: '', logo: '/assets/xt-exchange-logo.png', href: 'https://www.xt.com/en/trade/labubu_usdt' },
    { name: '', logo: '/assets/DexScreener_Exchange.png', href: 'https://dexscreener.com/solana/fyanfcdjkcfakbtzqixntqnvlodjjft82l5ffgpaswe3' },
    { name: '', logo: '/assets/Raydium_Exchange.png', href: 'https://raydium.io/swap/?inputMint=sol&outputMint=JB2wezZLdzWfnaCfHxLg193RS3Rh51ThiXxEDWQDpump' },
    { name: '', logo: '/assets/Dextools_Exchange.png', href: 'https://www.dextools.io/app/en/solana/pair-explorer/FYAnFcdjkcfAkbtZqixnTqNVLoDjJft82L5FFgPaSWe3?t=1728601307791' }
  ];

  return (
    <section id="dex-list" className="py-8 md:py-16 bg-labubu-pink-100">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-8 text-pink-800">Where to Buy</h2>
        <div className="flex flex-wrap justify-center items-center gap-4 md:gap-8">
          {exchanges.map((exchange, index) => (
            <div key={index} className="text-center w-1/2 sm:w-1/4 md:w-auto">
              <a href={exchange.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={exchange.logo}
                  alt={`${exchange.name} logo`}
                  className={`mx-auto mb-2 ${
                    exchange.logo.includes('xt-exchange-logo')
                      ? 'w-24 h-12 md:w-36 md:h-16'
                      : exchange.logo.includes('Raydium_Exchange')
                      ? 'w-38 h-16 md:w-54 md:h-24'
                      : exchange.logo.includes('DexScreener_Exchange')
                      ? 'w-60 h-14 md:w-54 md:h-20'
                      : exchange.logo.includes('Dextools_Exchange')
                      ? 'w-32 h-10 md:w-48 md:h-20'
                      : 'w-24 h-12 md:w-36 md:h-18'
                  }`}
                />
              </a>
              <p className="text-xs md:text-sm font-medium text-pink-800">{exchange.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DexList;
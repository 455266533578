import React from 'react';
import CountUp from 'react-countup';

const Tokenomics = () => {
  return (
    <section id="tokenomics" className="py-20 bg-labubu-pink-50 text-pink-800 min-h-20vh flex items-center">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8 text-pink-600">Tokenomics</h2>
        <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-12 mb-12">
          <div>
            <h3 className="text-3xl font-semibold text-pink-600">Total Supply</h3>
            <CountUp end={1000000000} duration={5} className="text-4xl font-bold" />
          </div>
          <div>
            <h3 className="text-3xl font-semibold text-pink-600">Circulating Supply</h3>
            <CountUp end={1000000000} duration={5} className="text-4xl font-bold" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-12">
          <button className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-6 px-12 rounded-lg text-2xl">
            0/0 TAX
          </button>
          <button className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-6 px-12 rounded-lg text-2xl">
            LP LOCKED FOREVER
          </button>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
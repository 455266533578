import React from 'react';

const FloatingBuyButton = () => {
  return (
    <a
      href="https://dexscreener.com/solana/fyanfcdjkcfakbtzqixntqnvlodjjft82l5ffgpaswe3"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-5 right-5 bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out z-50"
    >
      Buy $LABUBU
    </a>
  );
};

export default FloatingBuyButton;
import React from 'react';

const HowToBuySoL = () => {
  const steps = [
    {
      number: 1,
      title: 'ACQUIRE SOL',
      description: 'ENSURE YOU HAVE SOL IN YOUR WALLET BY TRANSFERRING OR PURCHASING IT FROM AN EXCHANGE SUCH AS BINANCE OR COINBASE.'
    },
    {
      number: 2,
      title: 'ACCESS RAYDIUM',
      description: 'VISIT RAYDIUM, CONNECT YOUR WALLET (ANY WALLET IS FINE, WE USE BACKPACK), AND PASTE THE $LABUBU TOKEN ADDRESS (JB2wezZLdzWfnaCfHxLg193RS3Rh51ThiXxEDWQDpump).'
    },
    {
      number: 3,
      title: 'SWAP SOL FOR $LABUBU',
      description: 'SWAP YOUR SOL FOR $LABUBU, THEN IMPORT THE $LABUBUL TOKEN TO YOUR WALLET.'
    },
    {
      number: 4,
      title: 'CONFIRM BALANCE',
      description: 'CHECK YOUR WALLET TO SEE YOUR $LABUBU BALANCE.'
    }
  ];

  return (
    <section id="howtobuysol" className="py-16 bg-labubu-pink-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-labubu-pink-900 text-center mb-10">HOW TO BUY</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {steps.map((step) => (
            <div key={step.number} className="bg-labubu-pink-400 rounded-lg p-6 relative">
              <div className="absolute top-0 left-0 bg-labubu-pink-800 text-white font-bold py-2 px-4 rounded-tl-lg rounded-br-lg">
                {step.number}
              </div>
              <h3 className="text-white font-bold text-xl mb-4 mt-8">{step.title}</h3>
              <p className="text-white text-sm">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowToBuySoL;
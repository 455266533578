import React from 'react';
import Particles from 'react-tsparticles';

const ParticlesBackground = () => {
    return (
        <Particles
          id="tsparticles"
          options={{
            background: {
              color: {
                value: 'transparent', // This allows the section background to show through
              },
            },
            particles: {
              number: {
                value: 100,
              },
              color: {
                value: "#000000", // Black color for particles
              },
              size: {
                value: 3,
              },
              links: {
                enable: true,
                color: "#000000", // Black color for links
                distance: 150,
                opacity: 0.5,
              },
              move: {
                enable: true,
                speed: 2,
              },
            },
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1, // Adjust this value as needed
          }}
        />
    );
};

export default ParticlesBackground;
import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 w-full bg-pink-100 text-pink-800 p-4 shadow-lg z-50">
      <nav className="flex flex-wrap justify-between items-center container mx-auto">
        <div className="flex items-center">
          <img src="/assets/tinylabubu.webp" alt="Labubu" className="w-8 h-8 mr-2 rounded-full" />
          <span className="text-2xl font-bold">LABUBU</span>
        </div>
        <button
          className="block md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <ul className={`${isMenuOpen ? 'block' : 'hidden'} md:flex md:items-center w-full md:w-auto mt-4 md:mt-0`}>
          <li><a href="#howtobuysol" className="block py-2 md:py-0 md:px-4 text-pink-800 hover:text-pink-600">How To Buy</a></li>
          <li><a href="#tokenomics" className="block py-2 md:py-0 md:px-4 text-pink-800 hover:text-pink-600">Tokenomics</a></li>
          <li><a href="#roadmap" className="block py-2 md:py-0 md:px-4 text-pink-800 hover:text-pink-600">Roadmap</a></li>
          <li><a href="#about" className="block py-2 md:py-0 md:px-4 text-pink-800 hover:text-pink-600">Contact</a></li>
        </ul>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="https://x.com/labubu_CTO" target="_blank" rel="noopener noreferrer">
            <img src="/assets/x.png" alt="Twitter" className="w-8 h-8 md:w-10 md:h-10 bg-pink-800 rounded-full" />
          </a>
          <a href="https://dexscreener.com/solana/fyanfcdjkcfakbtzqixntqnvlodjjft82l5ffgpaswe3" target="_blank" rel="noopener noreferrer">
            <img src="/assets/dexscreener.png" alt="DexScreener" className="w-8 h-8 md:w-10 md:h-10 bg-pink-800 rounded-full" />
          </a>
          <a href="https://t.co/UoNEHrcSN3" target="_blank" rel="noopener noreferrer">
            <img src="/assets/telegram.png" alt="Telegram" className="w-8 h-8 md:w-10 md:h-10 bg-pink-800 rounded-full" />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
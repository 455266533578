import React from 'react';

const Roadmap = () => {
  const phases = [
    {
      title: "Phase 1: Labubu Invasion",
      description: "Unleash an army of cute Labubus onto Solana. Warning: May cause extreme cuteness overload!",
      image: "/assets/roadmap-pictures/3.png"
    },
    {
      title: "Phase 2: Meme Mastery",
      description: "Achieve meme enlightenment. Our goal: Make even Elon Musk jealous of our meme game.",
      image: "/assets/roadmap-pictures/7.png"
    },
    {
      title: "Phase 3: Labubu Space Program",
      description: "Launch a Labubu to the moon (literally). First stop: Moon. Next stop: Mars!",
      image: "/assets/roadmap-pictures/11.png"
    },
    {
      title: "Phase 4: Labubu Virtual Reality",
      description: "Create a VR world where you can pet virtual Labubus. Warning: May be more addictive than social media.",
      image: "/assets/roadmap-pictures/9.png"
    },
    {
      title: "Phase 5: Labubu Governance",
      description: "Implement a DAO where Labubus make all the decisions. What could possibly go wrong?",
      image: "/assets/roadmap-pictures/13.png"
    },
    {
      title: "Phase 6: Labubu World Domination",
      description: "Replace all world leaders with Labubus. Achieve world peace through cuteness.",
      image: "/assets/roadmap-pictures/15.png"
    }
  ];

  return (
    <section id="roadmap" className="py-20 bg-labubu-pink-100 text-labubu-pink-800 min-h-screen flex items-center">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12 text-labubu-pink-600 glow">Roadmap to Cuteness</h2>
        <div className="bg-white rounded-3xl shadow-2xl p-8">
          <div className="space-y-16">
            {phases.map((phase, index) => (
              <div key={index} className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} items-center justify-center`}>
                <div className="w-full md:w-1/2 px-4 mb-6 md:mb-0">
                  <img src={phase.image} alt={phase.title} className="rounded-lg shadow-md max-w-full h-auto" />
                </div>
                <div className="w-full md:w-1/2 text-left px-4">
                  <div className="bg-labubu-pink-100 rounded-lg p-6 shadow-md">
                    <h3 className="text-2xl font-semibold text-labubu-pink-500">{phase.title}</h3>
                    <p className="text-labubu-pink-700 mt-2">{phase.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
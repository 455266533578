import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="about" className="py-20 bg-labubu-pink-50">
      <div className="container mx-auto" data-aos="fade-up">
        <h2 className="text-4xl font-bold text-center mb-8 text-pink-600">Contact Us</h2>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2">
            <img src="/assets/labubuabout.png" alt="About Labubusol" className="w-full" />
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 md:pl-8">
            <p className="text-xl font-bold text-pink-800">Labubu mania has hit Solana! 🎉🚀 These adorable monsters are taking over with their cuteness overload!

Get ready for a Labubu revolution - collecting, trading, and spreading joy across the blockchain! 🌈✨</p>
<br></br>
<p className="text-xl font-bold text-pink-800">Contact us on Telegram, Twitter, or Email us at contact@labubucto.com</p>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-2xl font-bold text-center m-8 text-pink-600">
        Disclaimer: $LABUBU is a meme fan token about the Labubu doll and is intended for entertainment purposes only. It does not promise or guarantee any financial returns. We are not in any way associated with Labubu Official or PopMART.
        </h2>
      </div>
    </section>
  );
};

export default About;